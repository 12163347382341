import React from "react";
import MainVideo from "./MainVideo";

const MainImage = () => {
  return (
    <div className="main-image-container h-full w-screen lg:h-max relative top-0 left-0">
      <div className="main-image-container-subcontainer w-192 relative lg:w-full lg:h-full lg:relative overflow-hidden">
        <div className="absolute bg-green-100 h-screen z-10 w-screen left-0 top-0 select-none flex items-center justify-center colour-pulse"></div>
        <MainVideo />
      </div>
    </div>
  );
};

export default MainImage;
