import React from "react";
import MainImage from "./MainImage";

const MainPage = () => {
  return (
    <div className="container-main lg:relative h-max w-screen lg:mb-0">
      <MainImage />
    </div>
  );
};

export default MainPage;
