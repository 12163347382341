import React from "react";
import "./HeaderAnimation.css";

const HeaderAnimation = () => {
  return (
    <div className="main-header-animation-container poppins-bold overflow-hidden max-h-10 lg:max-h-14">
      <div className="main-header-animation-text-container flex flex-col items-start relative select-none z-10">
        <div className="main-header-animation-text top-10 lg:top-12 lg:mb-2">
          efficiently
        </div>
        <div className="main-header-animation-text top-20 mt-1 lg:mt-0 lg:top-24 lg:mb-2">
          with ease
        </div>
        <div className="main-header-animation-text top-30 mt-1 lg:mt-0 lg:top-36 lg:mb-2">
          faster
        </div>
        <div className="main-header-animation-text top-40 mt-1 lg:mt-0 lg:top-48 lg:mb-2">
          efficiently
        </div>
      </div>
    </div>
  );
};

export default HeaderAnimation;
