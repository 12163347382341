import React from "react";
import Carousel from "react-material-ui-carousel";
import productPic from "../../images/productPic.png";

const content = [
  {
    subheading: "AI-driven analytics",
    body: "AI analytics transform SAL’s dashboard data into actionable insights—predicting maintenance, identifying risky behaviors, optimising fuel use, and providing real-time safety alerts.",
  },
  {
    subheading: "Centralised dashboard",
    body: "Comprehensive overview of real-time vehicle performance, driver behavior, and road conditions.",
  },
  {
    subheading: "Scalable SaaS platform",
    body: "SAL offers a scalable SaaS platform that easily integrates with existing systems, allowing businesses to expand effortlessly.",
  },
];

const ProductSection = () => {
  return (
    <div>
      {" "}
      <a id="Product" className="invisible text-xxs" href="/">
        Product
      </a>
      <div className="product-section-container flex flex-col lg:flex-row -mt-4 lg:mt-10 px-2 lg:px-0">
        <div className="product-section-subcontainer-left lg:w-1/2 flex flex-col lg:text-left lg:items-start lg:pr-24">
          <div className="product-section-left-header header poppins-semibold mb-8 lg:mb-0 lg:pb-10">
            <div className="justify-center header poppins-semibold">
              Product
            </div>
          </div>
          <div className="product-section-left-subheaders-container flex-col justify-evenly h-full hidden lg:flex">
            {content.map((item, index) => (
              <div className="grow" key={index}>
                <div
                  className={`product-section-left-subheading${
                    index + 1
                  } text-2xl`}
                >
                  {item.subheading}
                </div>
                <div
                  className={`product-section-left-body${
                    index + 1
                  } poppins-light text-lg text-gray-600 mt-2 mb-2`}
                >
                  {item.body}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="product-section-subcontainer-right rounded-xl grow lg:w-1/2 overflow-hidden">
          <img alt="product-specifications" src={productPic} height="150%" />
        </div>
        {/* mobile view */}
        <div className="product-section-container-mobile lg:hidden">
          <Carousel>
            {content.map((item, index) => (
              <div className="grow" key={index}>
                <div
                  className={`product-section-left-subheading${
                    index + 1
                  } text-xl`}
                >
                  {item.subheading}
                </div>
                <div
                  className={`product-section-left-body${
                    index + 1
                  } poppins-light text-md text-gray-600 mt-2`}
                >
                  {item.body}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
