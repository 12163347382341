import React, { useEffect } from "react";
import SalPic from "../images/SalPic.jsx";

import Button from "./Button.jsx";

const ContactUsModal = ({ show, updateModal }) => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "" + process.env.REACT_APP_ACCESS_KEY);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Successfully submitted.");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  useEffect(() => {
    const preventScroll = (event) => {
      event.preventDefault();
    };

    if (show) {
      document.body.style.overflow = "hidden";
      window.addEventListener("touchmove", preventScroll, { passive: false });
    } else {
      document.body.style.overflow = "auto";
      window.removeEventListener("touchmove", preventScroll);
    }

    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("touchmove", preventScroll);
    };
  }, [show]);

  return (
    <div
      className={
        (show ? "visible" : "invisible") +
        " contact-us-modal-container flex flex-col fixed z-50 left-0 top-0 w-full h-full bg-bg rounded-2xl p-4 lg:p-0 mb-12"
      }
    >
      <div className="contact-us-modal-header-container max-w-7xl self-center w-full lg:w-5/6 flex items-center justify-between">
        <div className="logo ml-4 flex w-fit text-black gap-x-1 lg:gap-x-2 items-center">
          <SalPic className="w-10 h-10 lg:w-20 lg:h-20" />
          <div className="poppins-medium text-lg lg:text-2xl text-nowrap">
            Save a Life
          </div>
        </div>
        <div className="exit-icon w-max flex justify-end lg:w-5/6 lg:max-w-6xl lg:mx-auto lg:mr-10">
          <div
            className="contact-us-modal-button-container hover:cursor-pointer"
            onClick={() => {
              updateModal();
            }}
          >
            <Button text="Home" type="submit" onclick={() => {}} />
          </div>
        </div>
      </div>
      <div className="contact-us-modal-body-container justify-center flex flex-col content-center h-full">
        <div className="poppins-semibold text-3xl lg:text-4xl mb-4">
          Contact Us!
        </div>
        <div className="poppins-regular text-sm lg:text-md  mb-4">
          Let's get in touch and discuss your enquiry further :)
        </div>
        <div className="contact-us-modal-input-container w-3/4 lg:w-112 self-center justify-center">
          <form onSubmit={onSubmit}>
            <input type="text" name="name" required placeholder="your name" />
            <input
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              name="phone"
              required
              placeholder="your phone"
            />
            <input
              type="email"
              name="email"
              required
              placeholder="your email"
            />
            <textarea
              name="message"
              required
              placeholder="your message"
              style={{ maxHeight: "15vh", minHeight: "80px" }}
            />
            <button type="submit">
              <Button type="submit" text="submit" onclick={() => {}} />
            </button>
          </form>
          <span className="mt-4">{result}</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;
