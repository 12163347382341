import React from "react";
import LinkedInLogo from "../../src/images/LinkedInLogo.png";

const Footer = () => {
  return (
    <div className="container-footer flex justify-center mt-36 py-4 font-medium">
      <div className="container-linkedin flex items-center w-full justify-center">
        Visit us on{" "}
        <a href="https://www.linkedin.com/company/sal-save-a-life/">
          <img
            alt="linkedin"
            className="ml-2 max-h-5 logo hover:shadow-lg"
            src={LinkedInLogo}
          ></img>
        </a>
      </div>
    </div>
  );
};

export default Footer;
