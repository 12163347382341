import React from "react";

const TeamMemberCard = ({ image, name, role, description }) => {
  return (
    <div className="team-member-card h-full flex flex-col">
      <div className="team-member-card-image">
        <img src={image} alt={name + " - " + role} />
      </div>
      <div className="team-member-card-body-container w-full rounded-lg p-8 grow">
        <div className="team-member-card-name mb-10 poppins-semibold text-xl lg:text-2xl">
          {name} - {role}
        </div>
        <div className="team-member-card-description text-left text-md lg:text-lg">
          {description}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;
