import React from "react";

const ProcessCard = ({ image, heading, description }) => {
  return (
    <div className="process-card-container grow flex flex-col items-start w-full">
      <div className="process-card-image flex items-center justify-center max-h-56 overflow-hidden lg:rounded-lg">
        <img src={image} alt={heading} />
      </div>
      {heading === "Powered by CUDA" && (
        <div className="relative h-0 w-full top-0 text-xs text-right">
          <div>(Image credit: Nvidia)</div>
        </div>
      )}
      <div className="process-card-heading mt-4 poppins-regular text-xl lg:text-2xl">
        {heading}
      </div>
      <div className="process-card-description text-left mt-2 poppins-light text-md lg:text-lg text-gray-600">
        {description}
      </div>
    </div>
  );
};

export default ProcessCard;
