import React from "react";
import ProcessCard from "../ProcessCard";
import processImage1 from "../../images/picProcess1.jpg";
import processImage2 from "../../images/picProcess2.jpg";
import processImage3 from "../../images/picNvidiaNPU.png";
import Carousel from "react-material-ui-carousel";

const processCards = [
  {
    image: processImage1,
    heading: "Hidden Markov Model",
    description:
      "We incorporate Hidden Markov Models to detect lateral lane maneuvers by obtaining the stochastic relation between the speed of a car and the lane that it is driving on.",
  },
  {
    image: processImage2,
    heading: "Gaussian Mixture Model",
    description:
      "Incorporating Gaussian Mixture Models allows us to more accurately model complex lane structures, including multiple lanes, lane shifts, and merging lanes, thereby reflecting real-world driving conditions.",
  },
  {
    image: processImage3,
    heading: "Powered by CUDA",
    description:
      "NVIDIA’s cutting-edge AI framework, CV-CUDA, will be the interface that drives our models to maximise the performance of our models, enabling real-time processing faster than competitors.",
  },
];

const ProcessSection = () => {
  return (
    <div className="process-section-container px-2 -mt-10 lg:px-0">
      <div className="process-section-header">
        <a id="Process" className="invisible text-xxs" href="/">
          Process
        </a>
        <div className="justify-center header poppins-semibold my-8">
          Process
        </div>
      </div>
      <div className="process-section-cards-container content-evenly gap-x-4 hidden lg:flex">
        {processCards.map((card, index) => (
          <ProcessCard
            key={index}
            image={card.image}
            heading={card.heading}
            description={card.description}
          />
        ))}
      </div>
      {/* mobile view */}
      <div className="process-section-cards-container-mobile block lg:hidden">
        <Carousel>
          {processCards.map((card, index) => (
            <ProcessCard
              key={index}
              image={card.image}
              heading={card.heading}
              description={card.description}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ProcessSection;
