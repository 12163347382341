import React, { useState, useEffect } from "react";
import driving from "../../../images/driving.mp4";
import drivingPic from "../../../images/drivingPic.jpg";
import HeaderAnimation from "../HeaderAnimation";

function preload_image(url) {
  let img = new Image();
  img.src = url;
}

const MainVideo = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleScreenChange = (e) => setIsSmallScreen(e.matches);
    mediaQuery.addEventListener("change", handleScreenChange);
    setIsSmallScreen(mediaQuery.matches);
    return () => mediaQuery.removeEventListener("change", handleScreenChange);
  }, []);

  const handleMediaLoad = () => {
    setIsLoaded(true);

    isSmallScreen
      ? document.getElementById("main-pic").classList.add("zoom-in-and-blur")
      : document.getElementById("main-video").classList.add("blur-in");
  };

  preload_image(drivingPic);
  preload_image(driving);

  return (
    <>
      {isSmallScreen ? (
        <img
          alt="driving"
          id="main-pic"
          src={drivingPic}
          className="relative h-full w-full -left-1/4 top-0 lg:top-0 sm:left-0 lg:h-full lg:w-full select-none z-20"
          onLoad={handleMediaLoad}
        ></img>
      ) : (
        <video
          id="main-video"
          className="relative h-full w-full -left-1/4 top-0 lg:top-0 sm:left-0 lg:h-full lg:w-full select-none -scale-x-100 z-20"
          autoPlay="autoplay"
          muted
          loop
          onCanPlayThrough={handleMediaLoad}
        >
          <source src={driving} type="video/mp4" />
        </video>
      )}

      {isLoaded && (
        <div className="container-main-description select-none absolute top-1/3 flex flex-col gap-y-0 self-start ml-2 lg:ml-8 pt-24 lg:pt-10 lg:w-full z-30">
          <div className="flex flex-col self-center w-full max-w-7xl">
            <div className="container-main-header poppins-black gap-y-4 lg:gap-y-8 flex flex-col text-3xl lg:text-6.5xl items-start text-white">
              <div className="nowrap z-10"> Manage your</div>
              <div className="flex">
                <div className="mr-2 z-10">driving data </div>
                <HeaderAnimation />
              </div>
            </div>
            <div className="main-header-subtitle text-left text-white text-base lg:text-3.2xl z-10 opacity-75 mt-2 lg:mt-6 poppins-regular">
              <div>Transforming Road Safety,</div>
              <div> One Journey at a Time</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainVideo;
