import React from "react";
import OurStoryPic from "../../images/OurStoryPic.jpg";

const content = [
  "At SAL, we believe technology has the power to save lives.",
  "We are driven by the frustration of seeing preventable accidents and the lack of real solutions that truly make a difference.",
  "The current solutions available in the market are locked behind paywalls as optional features. At SAL, we are fixated on increasing accessibility to the masses.",
  "So, naturally, we built a platform fueled by a mission—one that uses advanced AI to not just react to road dangers but to anticipate and prevent them.",
  "For us, it’s personal. Every life saved, every accident prevented, brings us closer to a world where road safety isn’t just an afterthought—it’s a reality.",
  "-Team @ SAL",
];

const StorySection = () => {
  return (
    <div>
      <a id="OurStory" className="invisible text-xxs" href="/">
        Our Story
      </a>
      <div className="story-section-container items-center flex flex-col lg:flex-row mt-4 lg:mt-10 px-2 lg:px-0">
        <div className="story-section-subcontainer-left w-1/2 grow flex flex-col lg:text-left lg:items-start lg:pr-4">
          <div className="story-section-left-header header poppins-semibold mb-8 lg:mb-0 lg:pb-10">
            <div className="justify-center header poppins-semibold">
              Our Story
            </div>
          </div>
          <div className="story-section-left-subheaders-container flex-col justify-evenly h-full hidden lg:flex">
            {content.map((item, index) => (
              <div className="grow" key={index}>
                <div
                  className={`story-section-left-body${
                    index + 1
                  } poppins-regular text-sm lg:text-2xl text-black mt-2 mb-2`}
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="story-section-subcontainer-right w-104 h-104 hidden lg:block">
          <img
            className="story-section-image p-10 relative right-0"
            alt="people in a busy street"
            src={OurStoryPic}
          />
        </div>
        {/* mobile view */}
        <div className="story-section-container-mobile lg:hidden">
          {" "}
          {content.map((item, index) => (
            <div className="grow" key={index}>
              <div
                className={`story-section-left-body${
                  index + 1
                } poppins-regular text-lg text-black mt-2 mb-2`}
              >
                {item}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="story-section-underline mt-4 bg-black h-3 opacity-35 rounded-lg relative mx-4 lg:mx-0"></div>
    </div>
  );
};

export default StorySection;
