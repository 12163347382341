import React from "react";
import "./Button.css";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const Button = ({ text, type, onclick }) => {
  const types = {
    contactUs:
      "contactUs-button p-2.5 px-6 hover:cursor-pointer w-max h-fit max-h-fit rounded-md text-white bg-green-800 lg:bg-transparent lg:border-black lg:border-2 lg:text-black lg:hover:text-white lg:hover:border-bg lg:rounded-3xl",
    getInTouch:
      "button-main font p-2.5 px-6 hover:cursor-pointer w-max h-fit max-h-fit rounded-md",
    subscribe:
      "button-main submit-button font p-2.5 px-5 mt-4 hover:cursor-pointer w-max h-fit max-h-fit rounded-3xl self-center",
    submit:
      "button-main submit-button py-1 px-6 text-white rounded-3xl hover:border-bg",
  };
  const textTypes = {
    contactUs: "font-header font-bold",
    subscribe: "font-header text-white",
  };

  return (
    <div
      className={types[type]}
      onClick={() => {
        if (onclick !== null) {
          onclick();
        }
      }}
    >
      <Stack alignItems="center" direction="row" gap={1}>
        <Typography variant="body1">
          <span className={textTypes[type] + " select-none"}>{text}</span>
        </Typography>
      </Stack>
    </div>
  );
};

export default Button;
