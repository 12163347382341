import React from "react";
import TeamMemberCard from "../TeamMemberCard";
import tempPic from "../../images/whiteCircle.png";
import jasonpic from "../../images/picJason.png";
import timpic from "../../images/picTim.png";

const TeamSection = () => {
  return (
    <div className="team-section-container -mt-4 px-2 lg:px-0">
      <div className="team-section-header">
        <a id="Team" className="invisible text-xxs" href="/">
          Team
        </a>
        <div className="justify-center header poppins-semibold my-4">
          The Team
        </div>
      </div>
      <div className="team-section-cards-container flex flex-col lg:grid lg:grid-cols-3 gap-x-8">
        <TeamMemberCard
          image={tempPic}
          name={"Andrew"}
          role={"VP of Product"}
          description={
            "Bringing a background in tech, business, and, marketing to SAL, Andrew is focussing on streamlining the product and enhancing user experiences while driving innovation."
          }
        />
        <TeamMemberCard
          image={jasonpic}
          name={"Jason"}
          role={"COO"}
          description={
            "With expertise in operations, logistics, and creative consulting, Jason Specialises in optimising processes and managing high-value assets."
          }
        />
        <TeamMemberCard
          image={timpic}
          name={"Tim"}
          role={"Technical Lead"}
          description={
            "Specialising in full-stack development and architecting cloud solutions, Tim manages SAL’s technical operations to ensure that our product is secure and scalable."
          }
        />
      </div>
    </div>
  );
};

export default TeamSection;
